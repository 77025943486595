<template>
  <div class="grey lighten-5" style="height: 100%;">
      <div>
          <v-toolbar height="200" flat color="amber lighten-1">
              <!-- <v-img :src="require('../assets/main.jpg')" height="200" contain> -->
                  <v-container>
                    <!-- <v-row justify="center" align="center">
                            <p class="ma-0 black--text headline red--text" v-for="linklist in linklists" :key="linklist.text">
                                {{ linklist.text }}
                                <span class="pr-5 pl-3" v-if="!linklist.text">
                                    /
                                </span>
                            </p>
                    </v-row> -->
                    <v-row justify="center" align="center">
                            <h1 class="white--text">
                                Light of Glory Taxi is NEW NORMAL ready!
                            </h1>
                    </v-row>
                </v-container>
              <!-- </v-img> -->
          </v-toolbar>
          <v-container>
              <v-row justify="center" align="center">
                  <v-col cols="10">
                      <v-card flat>
                            <p class="pa-4">
                                <strong>Your safety</strong> is our main concern; that’s why we believe in implementing strict INSIDE-OUT safety measures for you and your family.
                                From our drivers to the comforts brought to you by our taxicab, we ensure that everything is thoroughly cleaned and sanitized;
                                since a well-groomed driver and a disinfected car reflect how we care for you.
                            </p>
                            <p class="text-md-center text-sm-left display-1 pa-5">
                                Let's see how we do it!
                            </p>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                       <v-card-title class="display-1 pa-0">
                            <v-col cols="auto" class="pt-0" id="fontchnge">
                                We start with our DRIVERS:
                            </v-col>
                            <v-col class="pt-0">
                                <v-card height="2" width="100%" color="amber lighten-1" flat></v-card>
                            </v-col>
                        </v-card-title>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat>
                            <p class="pa-4">
                                Upon entry to our company, taxi drivers are subjected to thermal scanning and hand sanitization. Face masks on.
                                Those with body temperature above 37.5C, experiencing cough, headache, or shortness of breath, and the like are 
                                strictly prohibited to report for work and drive.

                                <br>
                                <br>

                                We ensure that our drivers practice proper handwashing, <strong>personal hygiene and wearing face shield.</strong>
                                While waiting for dispatch, our drivers also observe and maintain social distancing
                            </p>

                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                        <v-card flat>
                                            <v-img :src="require('../assets/safety2.jpg')"></v-img>
                                            <p class="text-center title font-weight-regular">Thermal scanning</p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                        <v-card flat>
                                            <v-img :src="require('../assets/safety4.jpg')"></v-img>
                                            <p class="text-center title font-weight-regular">Driver's waiting / dispatching area</p>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                                        <v-card flat>
                                            <v-img :src="require('../assets/safety1.jpg')"></v-img>
                                            <p class="text-center title font-weight-regular">Driver's handwashing area</p>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat color="transparent">
                          <v-card-title class="display-1 pa-0">
                                <v-col cols="auto" id="fontchnge">
                                    Then our STAFF:
                                </v-col>
                                <v-col>
                                    <v-card height="2" width="100%" color="amber lighten-1" flat></v-card>
                                </v-col>
                            </v-card-title>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat>
                            <p class="text-center display-1 mt-4 mb-4">
                                In various areas of our operations, CONTACTLESS PROCEDURES are being implemented.
                            </p>

                            <p class="pa-4 title font-weight-regular">
                                &#9679; Taxicabs are refueled by gas station personnel with no contact with our drivers- all communications are by two-way radios.
                                <br>
                                &#9679; All employees including office staff are wearing face masks and maintaining social distancing at all times.
                                <br>
                                &#9679; Alcohols and footbath are provided outside offices to ensure sanitation of all offices and work areas.
                            </p>

                            <v-container>
                                <v-row>
                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/co1.jpg')"></v-img>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/co2.jpg')"></v-img>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/co3.jpg')"></v-img>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/co4.jpg')"></v-img>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat color="transparent">
                          <v-card-title class="display-1 pa-0">
                                <v-col cols="auto" id="fontchnge">
                                    Next, Your ride, our TAXICABS:
                                </v-col>
                                <v-col>
                                    <v-card height="2" width="100%" color="amber lighten-1" flat></v-card>
                                </v-col>
                            </v-card-title>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat>
                            <p class="pa-4 title font-weight-regular">
                                &#9679; Taxicabs are car washed DAILY - INSIDE OUT. Car Interiors are properly sanitized and wiped as well as the 
                                exteriors. With thorough car washing, we are confident that our units are safe and clean for your ride
                            </p>

                            <v-container>
                                <v-row>
                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/ta1.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Thermal scanning</p> -->
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/ta2.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Driver's waiting/dispatching area</p> -->
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/ta3.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Thermal scanning</p> -->
                                        </v-card>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-card flat>
                                            <v-img :src="require('../assets/ta4.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Driver's waiting/dispatching area</p> -->
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <p class="text-center display-1 mt-4 mb-4">
                                Taxicabs also undergo several clearances before leaving the garage to serve the riding public:
                            </p>

                            <p class="pa-4 title font-weight-regular">
                                &#9679; Mechanics ensure that the car's engine and accessories are working; and
                                <br>
                                &#9679; Dispatching officers and company nurse issue and check alcohol, Bleach solution, soap, and water, dry cloth, and paper
                                mats needed to sanitize the taxicabs throughout the day.
                            </p>

                            <v-container>
                                <v-row justify="center" align="center">
                                    <v-col cols="6">
                                        <v-card flat>
                                            <v-img :src="require('../assets/cle1.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Thermal scanning</p> -->
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <p class="text-center display-1 mt-4 mb-4 display-1 amber--text text--lighten-1 pb-5">
                                Your light of glory taxicabs and drivers are now ready to pick you up!
                            </p>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat color="transparent">
                          <v-card-title class="display-1 pa-0">
                                <v-col cols="auto" id="fontchnge">
                                    Finally, to you our <strong>MOST VALUED PASSENGERS:</strong>
                                </v-col>
                                <v-col>
                                    <v-card height="2" width="100%" color="amber lighten-1" flat></v-card>
                                </v-col>
                            </v-card-title>
                      </v-card>
                  </v-col>

                  <v-col cols="10">
                      <v-card flat>
                            <p class="text-start headline pa-4">
                                <strong>Welcome Aboard!</strong> our driver will politely remind you to sanitize with alcohol and we have provided paper mats 
                                especially for You to put your shoes on! We will also be requesting you to fill-up our "manifesto" for contact tracing.
                            </p>

                            <p class="pa-4 headline text-center font-weight-regular amber--text text--lighten-1">
                               NOW, WE'RE ALL SET!

                               <br>

                               Let's now reach your destination, <strong>SAFE</strong> and <strong>WORRY-FREE</strong> with Light of Glory Taxi
                            </p>

                            <v-container>
                                <v-row justify="center" align="center">
                                    <v-col cols="6">
                                        <v-card flat>
                                            <v-img :src="require('../assets/done.jpg')"></v-img>
                                            <!-- <p class="text-center title font-weight-regular">Driver's waiting/dispatching area</p> -->
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                      </v-card>
                  </v-col>
              </v-row>
          </v-container>
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            linklists: [
                {
                    text: 'Home',
                    disabled: true,
                    href: '/',
                },

                {

                },

                {
                    text: 'Learn more',
                    disabled: true,
                    href: '/',
                }
            ],
        }
    }
}
</script>
