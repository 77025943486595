<template>
  <div>
    <!-- Desktop View --> 
    <div v-if="responsive1">
      <v-card id="carousel" tile>
        <v-carousel 
          height="100%"
          interval="10000"
          :show-arrows="true"
          :cycle="cycle"
          :continuous="true"
          hide-delimiter-background
          hide-delimiters
          delimiter-icon="stop"
          v-model="carou"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-img :src="item.pic" height="100%">
              <v-toolbar color="transparent" flat height="90" id="gradient1" dark>
                <v-container fluid>
                  <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="12" lg="11" xl="10">
                      <div class="row pl-4 pr-4">
                        <p class="display-1 font-weight-regular ma-0">
                          Light of Glory Transport Services Inc.
                        </p>

                        <v-spacer></v-spacer>

                        <v-btn text rounded class="text-none mr-2 ml-2 amber lighten-1" to="/">
                          Home
                        </v-btn>

                        <v-hover
                          v-slot:default="{ hover }"
                          open-delay="0"
                        >
                          <v-btn
                            class="text-none mr-2 ml-2"
                            :class="hover ? 'amber lighten-1' : ' '"
                            rounded
                            text
                            @click="$vuetify.goTo(target, options)"
                          >
                            Contact Us
                          </v-btn>
                        </v-hover>

                        <Register/>

                        <v-hover
                          v-slot:default="{ hover }"
                          open-delay="0"
                        >
                          <v-btn
                            class="text-none mr-2 ml-2"
                            :class="hover ? 'amber lighten-1' : ' '"
                            rounded
                            text
                            @click.stop="showRegister"
                          >
                            Sign In
                          </v-btn>
                        </v-hover>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-toolbar>

              <v-container v-if="bcard == false" fluid>
                <v-row align="center" justify="center">
                  <v-col cols="11" sm="12" md="10" lg="10" xl="10">
                    <v-row align="center" justify="start" style="height: 70vh;">
                      <v-col cols="3" sm="5" md="4" lg="3" xl="3">
                        <v-card id="color1" class="mt-12 mr-auto" dark>
                          <v-card-title class="headline font-weight-light" id="animc1">
                              Our Clean Commitment
                          </v-card-title>
                          <v-card-text class="title font-weight-light" id="animc2">
                              The highest standard in cleanliness.
                            <br>
                            <v-btn text link small class="mt-5 pl-0" depressed>
                              Learn More
                            </v-btn>
                            <br>
                          </v-card-text>
                        </v-card>
                        <div class="text-center">
                          <v-btn rounded link x-large class="mt-5" id="btncolor" block depressed @click="bcard = true">
                            Book Now
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>

    <div v-if="responsive1">
      <v-container v-if="bcard == true" fluid>
        <v-row align="center" justify="center">
          <v-col cols="11" sm="11" md="11" lg="11" xl="11">
            <v-row align="start" justify="center">
              <v-col cols="11" sm="11">
                <v-card width="450" class="mr-auto" id="flyincard" dark>
                  <v-tabs fixed-tabs height="50" color="white" background-color="#ffca28" active-class="white black--text" v-model="tab">
                      <v-tab class="text-none">
                        Request Now
                      </v-tab>
                      <v-divider vertical class="white--text"></v-divider>
                      <v-tab class="text-none">
                        Reservation
                      </v-tab>
                      <v-tab-item>
                        <v-card flat id="trans">
                          <v-card-text class="pt-0">
                            <v-container>
                              <v-row>
                                <v-col cols="12" class="pt-0 pb-0">
                                  <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-2">
                                  <p class="text-center ma-0">Complete The Form</p>
                                </v-col>
                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                  <v-text-field
                                    label="Access Code"
                                    dense
                                    outlined
                                    v-model="rcode"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="rfname"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Last Name"
                                    dense
                                    outlined
                                    v-model="rlname"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Current Location"
                                    dense
                                    outlined
                                    v-model="rloc"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Mobile/Tel. No."
                                    dense
                                    outlined
                                    v-model="rno"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pt-0 pb-4">
                                  <p class="ma-0 text-center">Optional Field</p> 
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-combobox
                                    label="Preferred Unit"
                                    :items="tunit"
                                    dense
                                    outlined
                                    v-model="rpunit"
                                  ></v-combobox>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-text-field
                                    label="Driver"
                                    dense
                                    outlined
                                    v-model="rdriver"
                                  ></v-text-field>
                                </v-col>

                                <v-col class="pa-0">
                                  <p class="ma-0 error--text text-center title" v-if="rerr">{{ rerr }}</p>
                                  <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                </v-col>

                                <v-col cols="12">
                                    <div class="text-center">
                                        <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                    </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn rounded class="text-none white--text" block color="#ffca28" :disabled="btndisabled" @click="requestNow">Send Request</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-tab-item>

                    <v-tab-item>
                      <v-card flat id="trans">
                        <v-card-text class="pt-0">
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="pt-0 pb-0">
                                <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                              </v-col>
                              <v-col cols="12" class="pt-0 pb-2">
                                <p class="text-center ma-0">Complete The Form</p>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                <v-text-field
                                  label="Access Code"
                                  dense
                                  outlined
                                  v-model="bcode"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="First Name"
                                  dense
                                  outlined
                                  v-model="bfname"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Last Name"
                                  dense
                                  outlined
                                  v-model="blname"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Pickup Location"
                                  dense
                                  outlined
                                  v-model="bloc"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Mobile/Tel. No."
                                  dense
                                  outlined
                                  v-model="bno"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pt-0 pb-4">
                                  <p class="ma-0 text-center">Optional Field</p> 
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-combobox
                                    label="Preferred Unit"
                                    :items="tunit"
                                    dense
                                    outlined
                                    v-model="rpunit"
                                  ></v-combobox>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-text-field
                                    label="Driver"
                                    dense
                                    outlined
                                    v-model="rdriver"
                                  ></v-text-field>
                                </v-col>

                              <v-col class="pa-0">
                                <p class="ma-0 error--text text-center  title" v-if="rerr">{{ rerr }}</p>
                                <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                              </v-col>

                              <v-col cols="12">
                                <div class="text-center">
                                  <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                </div>
                              </v-col>
                              
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn rounded class="text-none white--text" block color="#ffca28" :disabled="btndisabled" @click="requestNow">Send Reservation</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
      <v-row align="start" justify="center" id="cont">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <h1 class="font-weight-light text-center" ref="Contact" id="contact">Call us</h1>
          <v-card height="3" width="220" class="mx-auto mb-12" tile color="#024"></v-card>
        </v-col>
        <v-col cols="12" sm="12" md="11" xl="9" lg="9">
          <v-row align="start" justify="center">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-card min-height="230" height="330">
                <v-card id="ccard" flat class="mx-auto amber lighten-1">
                  <v-icon x-large id="ci1" color="white">fas fa-tty</v-icon>  
                </v-card>
                <h1 class="font-weight-light text-center">Landline:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    329-3166,  329-1316
                    <br>
                    320-5147,  328-9028
                    <br>
                    328-3443,  328-3427
                    <br>
                    328-9041,  320-5000
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-card min-height="230" height="330">
                  <v-card id="ccard" flat class="mx-auto amber lighten-1">
                    <v-icon x-large id="ci1" color="white">fas fa-phone</v-icon>  
                  </v-card>
                  <h1 class="font-weight-light text-center">Globe:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    0906-594-3281
                    <br>
                    0906-763-6305
                    <br>
                    0945-697-4043
                  </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-card min-height="230" height="330">
                  <v-card id="ccard" flat class="mx-auto amber lighten-1">
                    <v-icon x-large id="ci1" color="white">fas fa-phone</v-icon>  
                  </v-card>
                  <h1 class="font-weight-light text-center">Smart:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    0906-594-3281
                    <br>
                    0906-763-6305
                    <br>
                    0945-697-4043
                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center mt-5">
      <h1 class="font-weight-light text-center">Your Safety Comes First</h1>
      <v-card height="3" width="220" class="mx-auto" tile color="#024"></v-card>
    </div>

    <div class="mt-7">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="11" lg="8" xl="8">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" flat>
                  <v-carousel hide-delimiter-background :show-arrows="false" hide-delimiters interval="5000" cycle>
                    <v-carousel-item
                      v-for="(card,i) in cards"
                      :key="i"
                      :src="card.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card
                    width="400"
                    class="mt-4"
                    v-if="responsive2"
                  >
                    <v-card-title>
                      <h1 class="font-weight-light headline">
                        Committed to your safety
                      </h1>
                    </v-card-title>

                    <v-card-text class="font-weight-light subtitle-1 black--text">
                     Safer than mass public transport. - Taking a taxi is a quarantne ride to your
                     destination.There is no mass Gathering - only social distancing. And we 
                     thoroughly sanitize our taxicabs daily to make sure that every ride is 
                     safe and clean. Ride with us. Your safety comes first.
                      <br>
                    </v-card-text>
                  </v-card>

                  <v-card
                    width="400"
                    id="card1"
                    v-if="responsive1"
                  >
                    <v-card-title>
                      <h1 class="font-weight-light headline">
                        Committed to your safety
                      </h1>
                    </v-card-title>

                    <v-card-text class="font-weight-light subtitle-1 black--text">
                     Safer than mass public transport. - Taking a taxi is a quarantne ride to your
                     destination.There is no mass Gathering - only social distancing. And we 
                     thoroughly sanitize our taxicabs daily to make sure that every ride is 
                     safe and clean. Ride with us. Your safety comes first.
                      <br>
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container fluid>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="10" lg="8" xl="8">
            <v-row>
              <v-col cols="12" sm="12" md="6" order="2">
                <v-card class="mx-auto" flat>
                  <v-card-title>
                    <h1 class="headline">
                      Transportation Solution
                    </h1>
                  </v-card-title>

                  <v-card-text class="subtitle-1">
                    It's very expensive to buy and own a car. The outright cash 
                    outlay, the yearly car insurance, and the forthcoming installments
                    all cost thousands. You also need to maintain and take care of your car,
                    gas and wash it often, and find a suitable parking area for it. Yet, it 
                    depreciates over time. A car is never an investment. Save and invest your 
                    money elsewhere where it will grow. Let us fill your transportation needs.
                    Just call and we will be there.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="12" md="6" order="1">
                <v-card>
                  <v-img :src="testpic"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-footer
        dark
        padless
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          color="#040C23"
          width="100%"
        >
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">{{ icon }}</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            <v-row no-gutters justify="center" align="start">
              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  About
                </p>

                <v-btn text class="text-none">
                  About Us
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Careers
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  News Hub
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Mobile App
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Customer Service
                </p>

                <v-btn text class="text-none">
                  Need Help
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Tweet Us
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Comment/Complaint
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Site help
                </p>

                <v-btn text class="text-none">
                  Login Help
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Accessibility
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Policies
                </p>

                <v-btn text class="text-none">
                  Customer Commitment
                </v-btn>
              </v-col>
            </v-row>       
          </v-card-text>     
        </v-card>
      </v-footer>
    </div>
    <!-- Desktop View --> 











    <!-- Mobile Breakpoint -->
    <div v-if="responsive2">
      <v-card id="carousel" tile>
        <v-carousel 
          height="100%"
          interval="10000"
          :show-arrows="true"
          :cycle="cycle"
          :continuous="true"
          hide-delimiter-background
          hide-delimiters
          delimiter-icon="stop"
          v-model="carou"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-img :src="item.pic" height="100%">
              <!-- <v-toolbar color="transparent" flat height="90" id="gradient1" dark>
                <v-container fluid>
                  <v-row justify="center" align="center">
                    <v-col cols="12" sm="12" md="12" lg="11" xl="10">
                      <div class="row pl-4 pr-4 mt-12">
                        <p class="display-1 font-weight-regular ma-0 mt-12">Light of Glory</p>

                        <v-spacer></v-spacer>

                        <v-btn text rounded class="text-none mr-2 ml-2 red accent-4" to="/">
                          Home
                        </v-btn>

                        <v-hover
                          v-slot:default="{ hover }"
                          open-delay="0"
                        >
                          <v-btn
                            class="text-none mr-2 ml-2"
                            :class="hover ? 'red accent-4' : ' '"
                            rounded
                            text
                            @click="$vuetify.goTo(target, options)"
                          >
                            Contact Us
                          </v-btn>
                        </v-hover>

                        <Register/>

                        <v-hover
                          v-slot:default="{ hover }"
                          open-delay="0"
                        >
                          <v-btn
                            class="text-none mr-2 ml-2"
                            :class="hover ? 'red accent-4' : ' '"
                            rounded
                            text
                            @click.stop="showRegister"
                          >
                            Sign In
                          </v-btn>
                        </v-hover>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-toolbar> -->
              <v-toolbar color="transparent" flat>
                <v-icon>fas fa-user</v-icon>
                <v-toolbar-title class="headline ml-3">
                  light of <strong></strong>
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-container v-if="bcard == false" fluid>
                <v-row align="center" justify="center">
                  <v-col cols="11" sm="12" md="10" lg="10" xl="10">
                    <v-row align="center" justify="start" style="height: 70vh;">
                      <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-card id="color1" class="mt-12 mr-auto" dark>
                          <v-card-title class="headline font-weight-light" id="animc1">
                              Our Clean Commitment
                          </v-card-title>
                          <v-card-text class="title font-weight-light" id="animc2">
                              The highest standard in cleanliness.
                            <br>
                            <v-btn text link small class="mt-5 pl-0" depressed>
                              Learn More
                            </v-btn>
                            <br>
                          </v-card-text>
                        </v-card>
                        <div class="text-center mt-10">
                          <v-btn rounded link x-large class="mt-5" id="btncolor" block depressed @click="$vuetify.goTo(mtarget, moptions)">
                            Book Now
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>

    <div v-if="responsive2">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row align="start" justify="center">
              <v-col cols="12">
                <h6 class="ma-0 text-center mb-3" id="fatext">Enjoy Comfortable Trip</h6>
                <p class="ma-0 text-center headline mt-4 font-weight-bold">Book Taxi Online Now</p>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card width="450" class="mr-auto" id="mbook" dark tile>
                  <v-tabs fixed-tabs height="50" color="white" background-color="#FFC000" active-class="white black--text" v-model="tab">
                      <v-tab class="text-none">
                        Request Now
                      </v-tab>
                      <v-divider vertical class="white--text"></v-divider>
                      <v-tab class="text-none">
                        Reservation
                      </v-tab>
                      <v-tab-item>
                        <v-card flat id="trans">
                          <v-card-text class="pt-0">
                            <v-container>
                              <v-row>
                                <v-col cols="12" class="pt-0 pb-0">
                                  <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-2">
                                  <p class="text-center ma-0">Complete The Form</p>
                                </v-col>
                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                  <v-text-field
                                    label="Access Code"
                                    dense
                                    outlined
                                    v-model="rcode"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="rfname"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Last Name"
                                    dense
                                    outlined
                                    v-model="rlname"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Current Location"
                                    dense
                                    outlined
                                    v-model="rloc"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Mobile/Tel. No."
                                    dense
                                    outlined
                                    v-model="rno"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pt-0 pb-4">
                                  <p class="ma-0 text-center">Optional Field</p> 
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-combobox
                                    label="Preferred Unit"
                                    :items="tunit"
                                    dense
                                    outlined
                                    v-model="rpunit"
                                  ></v-combobox>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-text-field
                                    label="Driver"
                                    dense
                                    outlined
                                    v-model="rdriver"
                                  ></v-text-field>
                                </v-col>

                                <v-col class="pa-0">
                                  <p class="ma-0 error--text text-center title" v-if="rerr">{{ rerr }}</p>
                                  <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                </v-col>

                                <v-col cols="10">
                                    <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn rounded class="text-none white--text" block color="amber lighten-1" :disabled="btndisabled" @click="requestNow">Send Request</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-tab-item>

                    <v-tab-item>
                      <v-card flat id="trans">
                        <v-card-text class="pt-0">
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="pt-0 pb-0">
                                <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                              </v-col>
                              <v-col cols="12" class="pt-0 pb-2">
                                <p class="text-center ma-0">Complete The Form</p>
                              </v-col>
                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                <v-text-field
                                  label="Access Code"
                                  dense
                                  outlined
                                  v-model="bcode"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="First Name"
                                  dense
                                  outlined
                                  v-model="bfname"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Last Name"
                                  dense
                                  outlined
                                  v-model="blname"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Pickup Location"
                                  dense
                                  outlined
                                  v-model="bloc"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                <v-text-field
                                  label="Mobile/Tel. No."
                                  dense
                                  outlined
                                  v-model="bno"
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" class="pt-0 pb-4">
                                  <p class="ma-0 text-center">Optional Field</p> 
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-combobox
                                    label="Preferred Unit"
                                    :items="tunit"
                                    dense
                                    outlined
                                    v-model="rpunit"
                                  ></v-combobox>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0">
                                  <v-text-field
                                    label="Driver"
                                    dense
                                    outlined
                                    v-model="rdriver"
                                  ></v-text-field>
                                </v-col>

                              <v-col class="pa-0">
                                <p class="ma-0 error--text text-center  title" v-if="rerr">{{ rerr }}</p>
                                <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                              </v-col>

                              <v-col cols="10">
                                <div class="text-center">
                                  <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                </div>
                              </v-col>
                              
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn rounded class="text-none white--text" size="50" block color="amber lighten-1" :disabled="btndisabled" @click="requestNow">Send Reservation</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
      <v-row align="start" justify="center" id="cont">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <h1 class="font-weight-light text-center" ref="Contact" id="contact">Call us</h1>
          <v-card height="3" width="220" class="mx-auto mb-12" tile color="#024"></v-card>
        </v-col>
        <v-col cols="12" sm="12" md="11" xl="8" lg="8">
          <v-row align="start" justify="center">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-card min-height="230" height="330" class="mb-5 mt-3">
                <v-card id="ccard" flat class="mx-auto amber lighten-1">
                  <v-icon x-large id="ci1" color="white">fas fa-tty</v-icon>  
                </v-card>
                <h1 class="font-weight-light text-center">Landline:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    329-3166,  329-1316
                    <br>
                    320-5147,  328-9028
                    <br>
                    328-3443,  328-3427
                    <br>
                    328-9041,  320-5000
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-card min-height="230" height="330" class="mb-5 mt-3">
                  <v-card id="ccard" flat class="mx-auto amber lighten-1">
                    <v-icon x-large id="ci1" color="white">fas fa-phone</v-icon>  
                  </v-card>
                  <h1 class="font-weight-light text-center">Globe:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    0906-594-3281
                    <br>
                    0906-763-6305
                    <br>
                    0945-697-4043
                  </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-card min-height="230" height="330" class="mb-5 mt-3">
                  <v-card id="ccard" flat class="mx-auto amber lighten-1">
                    <v-icon x-large id="ci1" color="white">fas fa-phone</v-icon>  
                  </v-card>
                  <h1 class="font-weight-light text-center">Smart:</h1>
                  <v-card-text class="text-center title font-weight-regular">
                    0906-594-3281
                    <br>
                    0906-763-6305
                    <br>
                    0945-697-4043
                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center mt-5">
      <h1 class="font-weight-light text-center">Your Safety Comes First</h1>
      <v-card height="3" width="220" class="mx-auto" tile color="#024"></v-card>
    </div>

    <div class="mt-7">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="11" lg="8" xl="8">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" flat>
                  <v-carousel hide-delimiter-background :show-arrows="false" hide-delimiters interval="5000" cycle>
                    <v-carousel-item
                      v-for="(card,i) in cards"
                      :key="i"
                      :src="card.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                  <v-card
                    width="400"
                    class="mt-4"
                    v-if="responsive2"
                  >
                    <v-card-title>
                      <h1 class="font-weight-light headline">
                        Committed to your safety
                      </h1>
                    </v-card-title>

                    <v-card-text class="font-weight-light subtitle-1 black--text">
                     Safer than mass public transport. - Taking a taxi is a quarantne ride to your
                     destination.There is no mass Gathering - only social distancing. And we 
                     thoroughly sanitize our taxicabs daily to make sure that every ride is 
                     safe and clean. Ride with us. Your safety comes first.
                      <br>
                    </v-card-text>
                  </v-card>

                  <v-card
                    width="400"
                    id="card1"
                    v-if="responsive1"
                  >
                    <v-card-title>
                      <h1 class="font-weight-light headline">
                        Committed to your safety
                      </h1>
                    </v-card-title>

                    <v-card-text class="font-weight-light subtitle-1 black--text">
                     Safer than mass public transport. - Taking a taxi is a quarantne ride to your
                     destination.There is no mass Gathering - only social distancing. And we 
                     thoroughly sanitize our taxicabs daily to make sure that every ride is 
                     safe and clean. Ride with us. Your safety comes first.
                      <br>
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container fluid>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="10" lg="8" xl="8">
            <v-row>
              <v-col cols="12" sm="12" md="6" order="2">
                <v-card class="mx-auto" flat>
                  <v-card-title>
                    <h1 class="headline">
                      Transportation Solution
                    </h1>
                  </v-card-title>

                  <v-card-text class="subtitle-1">
                    It's very expensive to buy and own a car. The outright cash 
                    outlay, the yearly car insurance, and the forthcoming installments
                    all cost thousands. You also need to maintain and take care of your car,
                    gas and wash it often, and find a suitable parking area for it. Yet, it 
                    depreciates over time. A car is never an investment. Save and invest your 
                    money elsewhere where it will grow. Let us fill your transportation needs.
                    Just call and we will be there.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="12" md="6" order="1">
                <v-card>
                  <v-img :src="testpic"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-footer
        dark
        padless
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          color="#040C23"
          width="100%"
        >
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
            >
              <v-icon size="24px">{{ icon }}</v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0">
            <v-row no-gutters justify="center" align="start">
              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  About
                </p>

                <v-btn text class="text-none">
                  About Us
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Careers
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  News Hub
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Mobile App
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Customer Service
                </p>

                <v-btn text class="text-none">
                  Need Help
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Tweet Us
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Comment/Complaint
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Site help
                </p>

                <v-btn text class="text-none">
                  Login Help
                </v-btn>

                <br>

                <v-btn text class="text-none">
                  Accessibility
                </v-btn>
              </v-col>

              <v-col cols="12" sm="3">
                <p class="headline grey--text">
                  Policies
                </p>

                <v-btn text class="text-none">
                  Customer Commitment
                </v-btn>
              </v-col>
            </v-row>       
          </v-card-text>     
        </v-card>
      </v-footer>
    </div>
    <!-- Mobile Breakpoint -->
  </div>
</template>

<script>
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  import Register from '../components/Register'
  import VueRecaptcha from 'vue-recaptcha';
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  export default {
    components: {
      Register,
      VueRecaptcha
   },
    data () {
      return {
        type: 'element',
        element: '#contact',
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),
        duration: 300,
        offset: 0,
        mtype: 'melement',
        melement: '#mbook',
        measing: 'easeInOutCubic',
        measings: Object.keys(easings),
        mduration: 300,
        moffset: 0,
        bcard: false,
        acode: false,
        berr: null,
        rerr: null,
        btndisabled : true,
        responsive1: false,
        responsive2: false,
        rdriver: '',
        rpunit: '',
        rcode: '',
        rfname: '',
        rlname: '',
        rloc: '',
        rno: '',
        bcode: '',
        bfname: '',
        blname: '',
        bpass: '',
        bloc: '',
        btime: '',
        bno: '',
        cycle: true,
        copt: 'play',
        carou: [],
        testpic: require('../assets/a1.jpg?lazy'),
        date: new Date().toISOString().substr(0, 10),
        mdate: false,
        book: null,
        tab: null,
        tunit: [
          'Hyundai',
          'Kia',
          'Toyota'
        ],
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
        icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-linkedin',
        'fab fa-instagram',
      ],
      sheets: [
        {name: 'Twitter', subtitle: 'Tweet us on Twitter', icon: 'https://image.flaticon.com/icons/svg/491/491548.svg',},
        {name: 'Facebook', subtitle: 'Follow us on Facebook', icon: 'https://image.flaticon.com/icons/svg/733/733547.svg', r: 'https://www.facebook.com/lightofglorytaxi/'},
        {name: 'Messenger', subtitle: 'Message us on messenger', icon: 'https://www.flaticon.com/premium-icon/icons/svg/2392/2392493.svg'},
        {name: 'Viber', subtitle: 'Call / message us on viber', icon: 'https://www.flaticon.com/premium-icon/icons/svg/2504/2504948.svg'},
      ],
      cards: [
        {
          src: require('../assets/s1.jpg?lazy'),
          text: ''
        },
        {
          src: require('../assets/s2.jpg?lazy'),
          text: ''
        }
      ],
        items: [
          {
            pic: require('../assets/p1.jpg?lazy'),
            title: 'Our Clean Commitment',
            sub: 'The highest standard in cleanliness is a united effort.',
            btn: 'Learn More',
            menu1: false
          },
          {
            pic: require('../assets/p2.jpg?lazy'),
            title: 'United Explorer Card',
            sub: 'Earn 40,000 bonus miles and enjoy 0$ intro annual fee.',
            btn: 'Join Now',
            menu1: false
          },
          {
            pic: require('../assets/p3.jpg?lazy'),
            title: 'Book With Flexibility',
            sub: 'There are no change fees when you book through May 31,2020',
            btn: 'Learn More',
            menu1: false
          },
          {
            pic: require('../assets/p4.jpg?lazy'),
            title: 'Loyalty Rewarded',
            sub: 'Start earning miles with MileagePlus',
            btn: 'Join Now',
            menu1: false
          },
          {
            pic: require('../assets/p5.jpg?lazy'),
            title: 'Loyalty Rewarded',
            sub: 'Start earning miles with MileagePlus',
            btn: 'Join Now',
            menu1: false
          },
        ],
      }
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      target () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.type]
      },
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
      mtarget () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.mtype]
      },
      moptions () {
        return {
          duration: this.mduration,
          offset: this.moffset,
          easing: this.measing,
        }
      },
    },

    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },

    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
      },
      beforeDestroy () {
        window.removeEventListener('resize', this.onResponsiveInverted)
      },  

    methods: {
      btnClicked () {
            this.btndisabled = false
      },

      cpause () {
        this.cycle = false,
        this.copt = 'pause'
      },

      cplay () {
        this.cycle = true,
        this.copt = 'play'
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        this.dmenu = false
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      onResponsiveInverted () {
        if (window.innerWidth < 960) {
          this.responsive1 = false
          this.responsive2 = true
        } else {
          this.responsive1 = true
          this.responsive2 = false
        }
      },
       requestNow(){
         // eslint-disable-next-line
            //console.log('Hello 1')
            if(this.rfname && this.rlname && this.rno && this.rloc){
              // eslint-disable-next-line
               //console.log('Hello 2') 
              this.btndisabled = true
              this.rerr = null
              //console.log('lat 2: '+this.lat)
              //Integer acezcode, String firstname, String lastname, String address, String mobile1) 
              var oRecord = [
                { acezcode: this.rcode ? this.rcode : 0, firstname: this.rfname, lastname: this.rlname,  
                  address: this.rloc, mobile1: this.rno
                }
              ]
              axios.post(API_URL+'/requestnow/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     this.rerr = response.data[0].errmsg
                     this.berr = null
                  }else{
                     this.initRequest()
                     this.bcard = false
                  }
                  // eslint-disable-next-line
                  console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  // eslint-disable-next-line
                  console.log(error)
              })
            }else{
              this.rerr = 'Incomplete Entry'
              this.berr = null
            }
       },
       reserveNow(){
            if(this.bfname && this.blname && this.bno && this.bloc){
              this.btndisabled = true
              this.berr = null
              //console.log('lat 2: '+this.lat)
              //Integer acezcode, String firstname, String lastname, String address, String mobile1) 
              var oRecord = [
                { acezcode: this.bcode ? this.bcode : 0, firstname: this.bfname, lastname: this.blname,  
                  address: this.bloc, mobile1: this.bno
                }
              ]
              axios.post(API_URL+'/booking/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     this.berr = response.data[0].errmsg
                     this.rerr = null
                  }else{
                     this.initReserve()
                  }
                  // eslint-disable-next-line
                  console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  // eslint-disable-next-line
                  console.log(error)
              })
            }else{
              this.berr = 'Incomplete Entry'
              this.rerr = null
            }
       },
       initRequest() {
          this.rcode = null,
          this.rlname = null,
          this.rfname = null,
          this.rloc = null,
          this.rno = null,
          this.rerr = null
       },
       initReserve() {
          this.bcode = null,
          this.blname = null,
          this.bfname = null,
          this.bloc = null,
          this.bno = null,
          this.berr = null 
       }
  },
}
</script>