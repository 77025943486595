<template>
    <div>
    <v-hover
        v-slot:default="{ hover }"
        open-delay="0"
    >
        <v-btn
            class="text-none mr-2 ml-2"
            :class="hover ? 'amber lighten-1' : ''"
            rounded
            text
            @click.stop="showRegister"
        >
            Register Now
        </v-btn>
    </v-hover>

    <v-dialog
      v-model="dialog"
      width="600"
      height="700"
    >
      <v-card>
          <v-card-title class="amber lighten-1">
              <span class="display-1 font-weight-regular white--text">Registration Form</span>
          </v-card-title>
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">                              
                    <v-container fluid fill-height>
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="Last Name"
                                    v-model="lastname"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="First Name"
                                    v-model="firstname"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
                                <v-text-field
                                    label="Address"
                                    v-model="address"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="Landline 1"
                                    v-model="landline1"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="Landline 2"
                                    v-model="landline2"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="Mobile 1"
                                    v-model="mobile1"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                                <v-text-field
                                    label="Mobile 2"
                                    v-model="mobile2"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
                                <v-text-field
                                    label="Email"
                                    v-model="email"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>


                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
                                <v-text-field
                                    label="Facebook"
                                    v-model="facebook"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
                                <v-text-field
                                    label="Password"
                                    v-model="password"
                                    :append-icon="show1 ? 'eye' : 'eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"
                                    dense
                                    outlined
                                    color="amber lighten-1"
                                ></v-text-field>
                            </v-col>
                                
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
                                <div class="justify-right text-right">
                                    <v-spacer></v-spacer>
                                    <v-btn class="blue darken-3 white--text" @click="saveInfo" :disabled="disable">Confirm</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  //import gmapsInit from '@/js/gmaps'
  //import VueRecaptcha from 'vue-recaptcha'
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  export default {
    data () {
      return {
        responsive1: false,
        responsive2: false,
        disable: false,
        dialog: false,
        show1: false,
        saveclick: false,
        e1: 1,
        unqctr: null,
        lastname: null,
        firstname: null,
        address: null,
        landline1: null,
        landline2: null,
        landline3: null,
        mobile1: null,
        mobile2: null,
        mobile3: null,
        email: null,
        facebook: null,
        password: null
      }
    },

    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.onResponsiveInverted)
    },


    methods: {
        onResponsiveInverted () {
        if (window.innerWidth < 960) {
          this.responsive1 = false
          this.responsive2 = true
        } else {
          this.responsive1 = true
          this.responsive2 = false
        }
    },
        btnClicked () {
            this.disable = false
        },

        showRegister() {
            this.dialog = true
        },
        saveInfo() {
           if(!this.saveclick){
              this.saveclick = true
              //console.log('lat 2: '+this.lat)
              //console.log('Address 2: '+this.address)
              var oRecord = [
                { unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
                  landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
                  mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
                  email: this.email, facebook: this.facebook, password: this.password          
                }
              ]
              axios.post(API_URL+'/register/',oRecord).then(response => {
                  this.dialog = false
                  this.saveclick = false
                  this.initValues()
                  this.disable = true
                  setTimeout(() => this.disable = false, 10000)
                  // eslint-disable-next-line
                  console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.saveclick = false
                  // eslint-disable-next-line
                  console.log(error)
              })
           }
        },
        initValues() {
            // unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
            // landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
            // mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
            // email: this.email, facebook: this.facebook, password: this.password          
           this.unqctr = null,
           this.lastname = null,
           this.firstname = null,
           this.address = null,
           this.landline1 = null,
           this.landline2 = null,
           this.landline3 = null,
           this.mobile1 = null,
           this.mobile2 = null,
           this.mobile3 = null,
           this.email = null,
           this.facebook = null,
           this.password = null
        },
    },
  }
</script>
