<template>
  <div style="height: 100%; background-color: #F5F5F5; overflow-x: hidden">
    <div>
      <v-card flat tile>
        <v-carousel
          cycle
          height="300"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
          >
            <v-sheet
              color="#F5F5F5"
              height="100%"
            >
              <v-container class="pb-0">
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col class="pb-0">
                    <v-card id="mcaro">
                      <v-img :src="item.pic" id="mzooming"></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card>

      <h1>
        <p class="text-center mt-0 mb-1 font-weight-regular headline">
          Light of Glory
        </p>
      </h1>

      <v-container class="pt-0">
        <v-row>
          <v-col class="pt-0">
            <v-card id="mcard" flat color="transparent">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <v-card class="mx-auto text-center" elevation="2" id="mcapp" @click="sheet = true">
                      <v-icon size="40" color="primary" class="pt-6 pb-6">
                        fas fa-car
                      </v-icon>
                    </v-card>
                    <div class="pa-2 pt-3">
                        <h1 class="text-center font-weight-light body-2 black--text">Book Now</h1>
                      </div>
                  </v-col>

                  <v-col cols="4">
                    <v-card class="mx-auto text-center" elevation="2" id="mcapp" @click="register = true">
                      <v-icon size="40" color="primary" class="pt-6 pb-6">
                        fas fa-map-marked-alt
                      </v-icon>
                    </v-card>
                    <div class="pa-2 pt-3">
                        <h1 class="text-center font-weight-light body-2 black--text">Register</h1>
                      </div>
                  </v-col>

                  <v-col cols="4">
                    <v-card class="mx-auto text-center" elevation="2" id="mcapp" @click="test()">
                      <v-icon size="40" color="primary" class="pt-6 pb-6">
                        fas fa-star-half-alt
                      </v-icon>
                    </v-card>
                    <div class="pa-2 pt-3">
                        <h1 class="text-center font-weight-light body-2 black--text">Rate Driver</h1>
                      </div>
                  </v-col>

                  <v-col cols="4">
                    <v-card class="mx-auto text-center" elevation="2" id="mcapp" @click="feedback = true">
                      <v-icon size="40" color="primary" class="pt-6 pb-6">
                        fas fa-comment-dots
                      </v-icon>
                    </v-card>
                    <div class="pa-2 pt-3">
                        <h1 class="text-center font-weight-light body-2 black--text">Feedback</h1>
                      </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      
      <v-card id="bnav">
        <v-bottom-navigation
          fixed
          v-model="bottomNav"
          shift
          active-class="primary--text"
        >
          <v-btn value="home" active-class="primary--text">
            <span>Home</span>
            <v-icon>fas fa-home</v-icon>
          </v-btn>

          <v-btn x-large active-class="primary--text">
            <span>Viber</span>
            <v-icon>fab fa-viber</v-icon>
          </v-btn>

          <v-btn active-class="primary--text">
            <span>Twitter</span>
            <v-icon>fab fa-twitter</v-icon>
          </v-btn>

          <v-btn active-class="primary--text">
            <span>Message</span>
            <v-icon>fab fa-facebook-messenger</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </div>
    <v-bottom-sheet v-model="sheet" width="100%" fullscreen>
      <v-sheet class="text-center" height="100%" tile>
        <v-toolbar flat>
          <v-btn icon @click="sheet = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p class="text-center ma-0">Booking</p>
        </v-toolbar>
        <v-container fluid style="height: 100%;">
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card class="mx-auto text-center">
                      <v-tabs fixed-tabs height="50" color="white" background-color="#FFC000" active-class="white black--text" v-model="tab">
                      <v-tab class="text-none">
                        Request Now
                      </v-tab>
                      <v-divider vertical class="white--text"></v-divider>
                      <v-tab class="text-none">
                        Reservation
                      </v-tab>

                      <!-- REQUEST -->
                      <v-tab-item>
                        <v-card flat id="trans">
                          <v-card-text class="pt-0">
                            <v-form ref="reqform" v-model="valid" :lazy-validation="lazy">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" class="pt-0 pb-0">
                                    <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                                  </v-col>
                                  <v-col cols="12" class="pt-0 pb-2">
                                    <p class="ma-0 text-center">Available in iloilo City and Province only</p>
                                    <p class="text-center ma-0">Complete The Form</p>
                                  </v-col>
                                  <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                    <v-text-field
                                      label="Access Code"
                                      dense
                                      outlined
                                      v-model="rcode"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                    <v-text-field
                                      label="Last Name"
                                      dense
                                      outlined
                                      v-model="rlname"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                    <v-text-field
                                      label="First Name"
                                      dense
                                      outlined
                                      v-model="rfname"
                                      :rules="reqRules"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                    <v-text-field
                                      label="Last Name"
                                      dense
                                      outlined
                                      v-model="rlname"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                    <v-text-field
                                      label="Current Location"
                                      dense
                                      outlined
                                      v-model="rloc"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                    <v-text-field
                                      label="Mobile/Tel. No."
                                      dense
                                      outlined
                                      v-model="rno"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" class="pt-0 pb-4">
                                    <p class="ma-0 text-center">Optional Field</p> 
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0">
                                    <v-combobox
                                      label="Preferred Unit"
                                      :items="tunit"
                                      dense
                                      outlined
                                      v-model="rpunit"
                                    ></v-combobox>
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0">
                                    <v-text-field
                                      label="Driver"
                                      dense
                                      outlined
                                      v-model="rdriver"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col class="pa-0">
                                    <p class="ma-0 error--text text-center title" v-if="rerr">{{ rerr }}</p>
                                    <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                  </v-col>

                                  <!-- <v-col cols="10">
                                      <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                  </v-col> -->
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn rounded class="text-none white--text" block color="amber lighten-1" :disabled="mobook" @click="requestNow" id="mbook">Send Request</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-tab-item>
                      <!-- END of REQUEST tab item-->


                      <!-- RESERVATION -->
                    <v-tab-item>
                      <v-card flat id="trans">
                        <v-card-text class="pt-0">
                          <v-form ref="bookform" v-model="valid" :lazy-validation="lazy">
                            <v-container>
                              <v-row>
                                <v-col cols="12" class="pt-0 pb-0">
                                  <v-checkbox label="With Access Code" v-model="acode"></v-checkbox>
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-2">
                                  <p class="ma-0 text-center">Available in iloilo City and Province only</p>
                                  <p class="text-center ma-0">Complete The Form</p>
                                </v-col>
                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                  <v-text-field
                                    label="Access Code"
                                    dense
                                    outlined
                                    v-model="bcode"
                                    :rules="reqRules"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == true">
                                    <v-text-field
                                      label="Last Name"
                                      dense
                                      outlined
                                      v-model="blname"
                                      :rules="reqRules"
                                      required
                                    ></v-text-field>
                                  </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="bfname"
                                    :rules="reqRules"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="6" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Last Name"
                                    dense
                                    outlined
                                    v-model="blname"
                                    :rules="reqRules"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Pickup Location"
                                    dense
                                    outlined
                                    v-model="bloc"
                                    :rules="reqRules"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pb-0 pt-0" v-if="acode == false">
                                  <v-text-field
                                    label="Mobile/Tel. No."
                                    dense
                                    outlined
                                    v-model="bno"
                                    :rules="reqRules"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" class="pt-0 pb-4">
                                    <p class="ma-0 text-center">Optional Field</p> 
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0">
                                    <v-combobox
                                      label="Preferred Unit"
                                      :items="tunit"
                                      dense
                                      outlined
                                      v-model="bpunit"
                                    ></v-combobox>
                                  </v-col>

                                  <v-col cols="6" class="pb-0 pt-0">
                                    <v-text-field
                                      label="Driver"
                                      dense
                                      outlined
                                      v-model="bdriver"
                                    ></v-text-field>
                                  </v-col>

                                <v-col class="pa-0">
                                  <p class="ma-0 error--text text-center  title" v-if="rerr">{{ rerr }}</p>
                                  <p class="ma-0 error--text text-center title" v-if="berr">{{ berr }}</p>
                                </v-col>

                                <!-- <v-col cols="10">
                                  <div class="text-center">
                                    <vue-recaptcha sitekey="6LdABcMUAAAAANpwwrh_pH1Bnx_vYOoTID4YwCtq" class="mx-auto" @verify="btnClicked"></vue-recaptcha>
                                  </div>
                                </v-col> -->     
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn rounded class="text-none white--text" size="50" block color="amber lighten-1" :disabled="mobook" @click="reserveNow" id="mbook">Send Reservation</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                    <!-- end of RESERVATION tab item -->

                  </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import axios from 'axios'
  import {API_URL} from '@/js/constantvars'
  //import Register from '../components/Register'
  // import VueRecaptcha from 'vue-recaptcha';
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  export default {
    components: {
      //Register,
      // VueRecaptcha
   },

    data () {
      return {
        sheet: false,
        msgdlg: '',
        msgcolor: '',
        msgicon: '',
        msgclass: '',
        bottomNav: 'home',
        test: false,
        unqctr: null,
        lastname: null,
        firstname: null,
        address: null,
        landline1: null,
        landline2: null,
        landline3: null,
        mobile1: null,
        mobile2: null,
        mobile3: null,
        email: null,
        facebook: null,
        password: null,
        show1: false,
        disable: false,
        registernotif: false,
        register: false,
        lazy: false,
        mobook: false,
        ttime: 20000,
        facezcode: '',
        flname: '',
        ffname: '',
        fcontact: '',
        fremarks: '',
        fplateno: '',
        femail: '',
        feedback: false,
        snackbar: false,
        type: 'element',
        element: '#contact',
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),
        duration: 300,
        offset: 0,
        mtype: 'melement',
        melement: '#mbook',
        measing: 'easeInOutCubic',
        measings: Object.keys(easings),
        mduration: 300,
        moffset: 0,
        ftype: 'felement',
        felement: '#feedback',
        feasing: 'easeInOutCubic',
        feasings: Object.keys(easings),
        fduration: 300,
        foffset: 0,
        bcard: false,
        acode: false,
        berr: null,
        rerr: null,
        btndisabled : true,
        responsive1: false,
        responsive2: false,
        rdriver: '',
        rpunit: '',
        rcode: '',
        rfname: '',
        rlname: '',
        rloc: '',
        rno: '',
        bdriver: '',
        bpunit: '',
        bcode: '',
        bfname: '',
        blname: '',
        bpass: '',
        bloc: '',
        btime: '',
        bno: '',
        cycle: true,
        copt: 'play',
        carou: [],
        testpic: require('../assets/a1.jpg?lazy'),
        date: new Date().toISOString().substr(0, 10),
        mdate: false,
        book: null,
        tab: null,
        valid: true,
        reqRules: [
          v => !!v || 'Name is required',
        ],
        tunit: [
          'Hyundai',
          'Kia',
          'Toyota'
        ],
        icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-linkedin',
        'fab fa-instagram',
      ],
      sheets: [
        {name: 'Twitter', subtitle: 'Tweet us on Twitter', icon: 'https://image.flaticon.com/icons/svg/491/491548.svg',},
        {name: 'Facebook', subtitle: 'Follow us on Facebook', icon: 'https://image.flaticon.com/icons/svg/733/733547.svg', r: 'https://www.facebook.com/lightofglorytaxi/'},
        {name: 'Messenger', subtitle: 'Message us on messenger', icon: 'https://www.flaticon.com/premium-icon/icons/svg/2392/2392493.svg'},
        {name: 'Viber', subtitle: 'Call / message us on viber', icon: 'https://www.flaticon.com/premium-icon/icons/svg/2504/2504948.svg'},
      ],
      cards: [
        {
          src: require('../assets/s1.jpg?lazy'),
          text: ''
        },
        {
          src: require('../assets/s2.jpg?lazy'),
          text: ''
        }
      ],
        items: [
          {
            pic: require('../assets/p6.jpg?lazy'),
            title: 'Our Clean Commitment',
            sub: 'The highest standard in cleanliness is a united effort.',
            btn: 'Learn More',
            menu1: false
          },
          {
            pic: require('../assets/p1.jpg?lazy'),
            title: 'United Explorer Card',
            sub: 'Earn 40,000 bonus miles and enjoy 0$ intro annual fee.',
            btn: 'Join Now',
            menu1: false
          },
          {
            pic: require('../assets/p2.jpg?lazy'),
            title: 'Book With Flexibility',
            sub: 'There are no change fees when you book through May 31,2020',
            btn: 'Learn More',
            menu1: false
          },
          {
            pic: require('../assets/p3.jpg?lazy'),
            title: 'Loyalty Rewarded',
            sub: 'Start earning miles with MileagePlus',
            btn: 'Join Now',
            menu1: false
          },
          {
            pic: require('../assets/p4.jpg?lazy'),
            title: 'Loyalty Rewarded',
            sub: 'Start earning miles with MileagePlus',
            btn: 'Join Now',
            menu1: false
          },
          {
            pic: require('../assets/p5.jpg?lazy'),
            title: 'Loyalty Rewarded',
            sub: 'Start earning miles with MileagePlus',
            btn: 'Join Now',
            menu1: false
          },
        ],
      }
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      target () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.type]
      },
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        }
      },
      mtarget () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.mtype]
      },
      moptions () {
        return {
          duration: this.mduration,
          offset: this.moffset,
          easing: this.measing,
        }
      },
      ftarget () {
        // const value = this[this.type]
        // if (!isNaN(value)) return Number(value)
        // else return value
        return this[this.ftype]
      },
      foptions () {
        return {
          duration: this.fduration,
          offset: this.foffset,
          easing: this.feasing,
        }
      },
    },

    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },

    mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
      },
      beforeDestroy () {
        window.removeEventListener('resize', this.onResponsiveInverted)
      },  

    methods: {
      btnClicked () {
            this.btndisabled = false
      },

      cpause () {
        this.cycle = false,
        this.copt = 'pause'
      },

      cplay () {
        this.cycle = true,
        this.copt = 'play'
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        this.dmenu = false
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      onResponsiveInverted () {
        if (window.innerWidth < 960) {
          this.responsive1 = false
          this.responsive2 = true
        } else {
          this.responsive1 = true
          this.responsive2 = false
        }
      },
       requestNow(){
         // eslint-disable-next-line
            //console.log('Hello 1')
            let cMsg = ''
            let isProceed = true
            if(this.acode){
               if(!this.rcode || !this.rlname){
                  isProceed = false
                  cMsg = 'Incomplete entry'
               }
            }else if(!(this.rfname && this.rlname && this.rloc && this.rno)){
               isProceed = false
               cMsg = `<strong>Incomplete Entry.</strong><br/><br/>
               Required information<strong>:</strong><br/>
               lastname, firstname, contact, location`
            }
            if(isProceed){
              // eslint-disable-next-line
               console.log('unit: '+this.rpunit) 
               console.log('driver: '+this.rdriver)
              this.btndisabled = true
              this.rerr = null
              //console.log('lat 2: '+this.lat)
              //Integer acezcode, String firstname, String lastname, String address, String mobile1) 
              var oRecord = [
                { acezcode: this.rcode ? this.rcode : 0, firstname: this.rfname, lastname: this.rlname,  
                  address: this.rloc, mobile1: this.rno, unit: this.rpunit ? this.rpunit : '',
                  driver: this.rdriver ? this.rdriver : '' 
                }
              ]
              axios.post(API_URL+'/requestnow/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     //this.rerr = response.data[0].errmsg
                     this.berr = null
                     this.showErrMsg(response.data[0].errmsg)
                  }else{
                     this.initRequest()
                     this.$refs.reqform.resetValidation()
                     this.mobook = true
                     this.bcard = false
                     this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                     //this.snackbar = true
                  }
                  // eslint-disable-next-line
                  console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg(error)
                  // eslint-disable-next-line
                  //console.log(error)
              })
              setTimeout(() => this.mobook = false, 10000)
              setTimeout(() => this.snackbar = false, 30000)
            }else{
              //this.rerr = 'Incomplete Entry'
              this.berr = null
              this.showErrMsg(cMsg)
            }
       },
       reserveNow(){
            //console.log('Reserve 0')
            let cMsg = ''
            let isProceed = true
            if(this.acode){
               //console.log('Reserve 1')
               if(!this.bcode || !this.blname){
                  //console.log('Reserve 2')
                  isProceed = false
                  cMsg = 'Incomplete entry'
               }
            }else if(!(this.bfname && this.blname && this.bloc && this.bno)){
               isProceed = false
               cMsg = `<strong>Incomplete Entry.</strong><br/><br/>
               Required information<strong>:</strong><br/>
               lastname, firstname, contact, location`
            }
            if(isProceed){
               //console.log('unit: '+this.bpunit) 
               //console.log('driver: '+this.bdriver)

              this.btndisabled = true
              this.berr = null
              //console.log('lat 2: '+this.lat)
              //Integer acezcode, String firstname, String lastname, String address, String mobile1) 
              var oRecord = [
                { acezcode: this.bcode ? this.bcode : 0, firstname: this.bfname, lastname: this.blname,  
                  address: this.bloc, mobile1: this.bno, unit: this.bpunit ? this.bpunit : '',
                  driver: this.bdriver ? this.bdriver : ''
                }
              ]
              axios.post(API_URL+'/booking/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     //this.berr = response.data[0].errmsg
                     this.rerr = null
                     this.showErrMsg(response.data[0].errmsg)
                  }else{
                     this.initReserve()
                     this.$refs.bookform.resetValidation()
                     this.mobook = true
                     this.bcard = false
                     this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                  }
                  // eslint-disable-next-line
                  //console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg(error)
                  // eslint-disable-next-line
                  //console.log(error)
              })
              setTimeout(() => this.mobook = false, 10000)
              setTimeout(() => this.snackbar = false, 30000)
            }else{
              //this.berr = 'Incomplete Entry'
              this.rerr = null
              this.showErrMsg(cMsg)
            }
       },

       sendFeedback(){
            //console.log('Hello 0')
            if(this.ffname && this.flname && this.fcontact && this.fremarks){
              this.btndisabled = true
              this.berr = null
              var oRecord = [
                { unqctr: '', acezcode: this.facezcode ? this.facezcode : 0, lastname: this.flname, 
                  firstname: this.ffname, contact: this.fcontact, plateno: this.fplateno, 
                  remarks: this.fremarks, email: this.femail, tdate: ''
                }
              ]
              axios.post(API_URL+'/feedback/',oRecord).then(response => {
                  this.btndisabled = false
                  if(response.data.length>0){
                     //this.berr = response.data[0].errmsg
                     this.rerr = null
                     //this.showErrMsg(response.data[0].errmsg)
                     this.showErrMsg('Problem encountered processing your feedback')
                  }else{
                     this.initFeedback()
                     this.bcard = false
                     this.feedback = false
                     this.showSuccessMsg('Thank You for choosing light of glory taxi. Our operator will get back as soon as possible...')
                     //this.snackbar = true
                  }
                  // eslint-disable-next-line
                  //console.log(response.data)
              }).catch(error => {
                  this.dialog = false
                  this.btndisabled = false
                  this.showErrMsg(error)
                  // eslint-disable-next-line
                  //console.log(error)
              })
            }else{
                this.showErrMsg(`<strong>Incomplete Entry.</strong><br/><br/>
                Minimum required information<strong>:</strong><br/>
                lastname, firstname, contact, feedback`)
            }
       },

       initRequest() {
          this.rcode = '',
          this.rlname = '',
          this.rfname = '',
          this.rloc = '',
          this.rno = '',
          this.rerr = '',
          this.rpunit = '',
          this.rdriver = ''
          //this.acode = false
       },
       initReserve() {
          this.bcode = '',
          this.blname = '',
          this.bfname = '',
          this.bloc = '',
          this.bno = '',
          this.berr = '' 
          this.bpunit = '',
          this.bdriver = ''
       },
       initFeedback() {
          this.facezcode = '',
          this.flname = '',
          this.ffname = '',
          this.fcontact = '',
          this.fremarks = '',
          this.femail = ''
       },
        showRegister() {
            this.register = true
        },
        saveInfo() {
            if(this.lastname && this.firstname && this.mobile1 && this.password){
              if(!this.saveclick){
                  this.saveclick = true
                  //console.log('lat 2: '+this.lat)
                  //console.log('Address 2: '+this.address)
                  var oRecord = [
                    { unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
                      landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
                      mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
                      email: this.email, facebook: this.facebook, password: this.password          
                    }
                  ]
                  axios.post(API_URL+'/register/',oRecord).then(response => {
                      this.register = false
                      this.saveclick = false
                      this.initValues()
                      this.showSuccessMsg('Thank you for your registration. Our team will get back within 24 hours to give you access code')
                      // eslint-disable-next-line
                      console.log(response.data)
                  }).catch(error => {
                      this.showErrMsg('Problem encountered processing your registration')
                      
                      this.register = false
                      this.saveclick = false
                      // eslint-disable-next-line
                      console.log(error)
                  })
              }
            }else{
                this.showErrMsg(`<strong>Incomplete Entry.</strong><br/><br/>
                Minimum required information<strong>:</strong><br/>
                lastname, firstname, mobile, password`)
            }  
        },
        initValues() {
            // unqctr: null, lastname: this.lastname, firstname: this.firstname, address:this.address,
            // landline1: this.landline1, landline2: this.landline2, landline3: this.landline3,
            // mobile1: this.mobile1, mobile2: this.mobile2, mobile3: this.mobile3, 
            // email: this.email, facebook: this.facebook, password: this.password          
           this.unqctr = null,
           this.lastname = null,
           this.firstname = null,
           this.address = null,
           this.landline1 = null,
           this.landline2 = null,
           this.landline3 = null,
           this.mobile1 = null,
           this.mobile2 = null,
           this.mobile3 = null,
           this.email = null,
           this.facebook = null,
           this.password = null,
           this.rerr = null
        },
        showErrMsg(cMsg) {
           this.msgicon = 'fas fa-exclamation-triangle'
           this.msgcolor = 'error'
           this.msgclass = 'error--text'
           this.msgdlg = cMsg   //.split('\n')
           this.snackbar = true
           setTimeout(() => this.snackbar = false, 30000)
        },
        showSuccessMsg(cMsg){
           this.msgicon = 'far fa-check-circle'
           this.msgcolor = 'success'
           this.msgclass = 'success--text'
           this.msgdlg = cMsg
           this.disable = true
           this.snackbar = true
           setTimeout(() => this.snackbar = false, 30000)
           setTimeout(() => this.disable = false, 10000)
           //this.registernotif = true
           //setTimeout(() => this.registernotif = false, 30000)
        }
  },
}
</script>